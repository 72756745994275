<template>
  <div>
    <section id="about" class="section-content">
      <div class="container">
        <div class="row">
          <div class="col-lg-7">
            <h2>Proteção solar, decoração e privacidade</h2>

            <h3>3M Scotchtint™</h3>

            <p>As películas de controle solar Scotchtint(MR) contam com qualidade assegurada pela 3M, além de oferecer uma estética diferenciada e a garantia de resistência a riscos.</p>
            <p>Fabricada com exclusivo filme de poliéster 3M, garante a redução da entrada de calor através do vidro, diminuindo os gastos com ar-condicionado e ventiladores e reduzindo os efeitos negativos do sol.</p>

            <h4>3M Scotchtint™ Night Vision Films</h4>

            <p>Os filmes da linha NV possuem tecnologia de menor reflexão interna e maior externa, diminuem o efeito espelho.</p>

            <h4>3M Scotchtint™</h4>
            <p>Valorização da estética do Ambiente Filmes Metalizados:</p>

            <ul class="list">
              <li>P 18.</li>
              <li>NV 15 ou 25.</li>
              <li>Linha BC 10/20/35.</li>
              <li>Filmes Fumê.</li>
              <li>FX 05/20/ 35.</li>
            </ul>

            <h3>3M Fasara™ Filmes Decorativos</h3>

            <p>Incorporando várias características inovadoras, a linha de Insulfilms decorativos 3M Fasara™ foi desenvolvida especialmente para fornecer a designers de interiores uma impressionante gama de opções criativas para decoração de vidros, com grande apelo arquitetônico e que podem ser feitos sob medida para se adequar a requerimentos e preferências individuais dos consumidores.</p>

            <p>A linha 3M Fasara ™ irá transformar o interior de ambientes comerciais, como prédios, lojas, restaurantes, aeroportos, hotéis, entre outros. As aplicações incluem divisões de vidro, janelas, portas, sinalizações corporativas e de segurança, desenhos decorativos e promocionais, entre outros.</p>

            <ul class="list">
              <li>Áreas privativas sem comprometimento da estética.</li>
              <li>Valorização do ambiente.</li>
              <li>Filmes em poliéster.</li>
            </ul>

            <h3>3M Prestige & Crystalline Film</h3>

            <p>A linha Prestige Crystalline é lançamento mundial da 3M, com uma tecnologia nova. Retenção de raios infravermelhos sem metal.</p>

            <p>
              Sem metais e com garantia de 15 anos, a linha Prestige Crystalline garante um redução de até 97% dos raios infravermelhos e 99,9% dos raios Ultravioletas. Quanto à luz visível, temos quatro opções:
              70 %, 60%, 50% e 40%.
            </p>

            <h4>O spectro solar é basicamente dividido em três tipos de raios:</h4>

            <ul class="list">
              <li>Infravermelho - raio que gera o calor através da radiação.</li>
              <li>Ultravioleta - responsável pelo desbotamento do interior.</li>
              <li>Luz visível - claridade, quanto menor a porcentagem de luz visível, mais escuro é o film.</li>
            </ul>

            <h4>Características</h4>

            <ul class="list">
              <li>Altíssima redução de radiação solar e maior conforto.</li>
              <li>
                Otimiza o funcionamento do equipamento de ar-condicionado e proporciona economia
                de energia.
              </li>
              <li>Protege os móveis e carpetes contra o desbotamento.</li>
              <li>Permite 70% da entrada de luz natural.</li>
              <li>Fabricação sem metais na composição, não sofre ação de corrosão e não interfere no sinal de telefones celulares.</li>
              <li>Baixíssima refletividade (&lt; 8%), evita efeito espelho.</li>
              <li>Resultados obtidos através de nano-tecnologia.</li>
              <li>(Inovação no mercado).</li>
              <li>Disponível na versão de segurança, linha Scotchshield.</li>
              <li>Perfeita visualização, o filme tem a mesma tecnologia de TV´S LCD.</li>
              <li>Garantia 3M de 15 anos.</li>
              <li>Discreta transparência.</li>
              <li>
                Não contém componentes voláteis na composição
                do adesivo.
              </li>
            </ul>

            <h3>Filmes de Segurança Scotchshield™</h3>

            <p>Estas películas são projetadas para manter os pedaços de vidros grudados no caso de quebra, reduzindo o risco de acidentes provocados por cacos arremessados.</p>

            <p>A película de Segurança Scotchshield(MR), fabricada com exclusivo filme transparente e multicamadas de poliéster da 3M, é capaz de segurar os estilhaços de vidro até mesmo em casos de explosão, diminuindo os riscos de ferimentos com esses estilhaços, em todas as situações.</p>

            <p>O adesivo utilizado nas películas 3M garante fácil aplicação, durabilidade e oferece ainda proteção contra os raios UV, nocivos à saúde de sua família.</p>
          </div>
          <!-- col end -->

          <div class="col-lg-5 section-content-illustration">
            <QuotationCall position="float" />
          </div>
          <!-- col end -->
        </div>
        <!-- row end -->
      </div>
    </section>
  </div>
</template>

<script>
import LayoutInternal from "@/layouts/LayoutInternal.vue";

//Lazy Loading
const QuotationCall = () =>
  import(
    /* webpackChunkName: "QuotationCall" */ "@/components/cotacao/quotation-call.vue"
  );

export default {
  components: {
    QuotationCall,
  },

  created() {
    this.$emit(`update:layout`, LayoutInternal);
    this.$emit(`update:title`, "Películas 3M");
    this.$emit(
      `update:introduction`,
      "Instalamos as películas 3M em comércios, sacadas, escritórios, apartamentos, divisórias, portarias e na arquitetura em geral"
    );
    this.$emit(`update:bgHeader`, "black");
  },
};
</script>

